/* !! This file must stay in sync between CFE and Livemap !! */

.wemap-template-icon-title {
  margin: 0;
  margin-top: 15px;
}

.wemap-template-icon-title > i {
  min-width: 30px;
  margin-right: 2px;
  text-align: center;
}

.wemap-template-icon-title > span {
  font-weight: 800;
}

.wemap-template-icon-without-title {
  margin: 0;
  margin-top: 15px;
}

.wemap-template-icon-without-title > i {
  min-width: 30px;
  margin-right: 2px;
  text-align: center;
}

.wemap-template-icon-content {
  margin-left: 32px;
  overflow-wrap: break-word;
}

a.wemap-template-button {
  display: block;
  margin: auto;
  text-align: center;
  border-radius: 4px;
  text-decoration: none;
  font-size: 16px !important;
  padding: 11px 12px 8px 12px;
  width: 100%;
  color: #ffffff;
  margin-top: 15px;
}

ul.wemap-template-oa-accessibility {
  padding: 0;
  overflow-x: auto;
  margin: 5px 0 14px 32px;
}

ul.wemap-template-oa-accessibility li {
  display: inline-block;
  margin: 0 17px 0 0;
  color: #2f7de1;
  text-align: center;
  line-height: 2em;

  &::before {
    display: block;
    width: 100%;
    min-width: 50px;
    height: 50px;
    opacity: 0.6;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    content: '';
  }

  &.wemap-template-oa-accessibility-mi::before {
    background-image: url('https://livemap.getwemap.com/img/mcc/icon_handicap_moteur-60x60.png');
  }

  &.wemap-template-oa-accessibility-hi::before {
    background-image: url('https://livemap.getwemap.com/img/mcc/icon_handicap_auditif-60x60.png');
  }

  &.wemap-template-oa-accessibility-pi::before {
    background-image: url('https://livemap.getwemap.com/img/mcc/icon_handicap_psychique-60x60.png');
  }

  &.wemap-template-oa-accessibility-vi::before {
    background-image: url('https://livemap.getwemap.com/img/mcc/icon_handicap_visuel-60x60.png');
  }

  &.wemap-template-oa-accessibility-sl::before {
    background-image: url('https://livemap.getwemap.com/img/mcc/icon_handicap_languesignes-60x60.png');
  }
}

ul.wemap-template-oa-accessibility li:last-child {
  margin-right: 0;
}
