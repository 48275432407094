.mapboxgl-map {
  .mapboxgl-popup-content:has(.select-choice-popup) {
    padding: 0px;

    .mapboxgl-popup-close-button {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }

  .select-choice-popup {
    padding: 10px 0px 15px 0px;

    h3 {
      font-size: 16px;
      font-weight: 600;
      padding: 6px 10px;
    }

    li {
      display: flex;
      align-items: center;
      padding: 4px 10px;
      transition: background-color 0.2s ease;
      cursor: pointer;

      &:hover {
        background-color: #f0f0f0;
      }
    }

    .circle {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 8px;
    }

    .feature-name {
      font-size: 14px;
      font-weight: 400;
    }
  }
}
