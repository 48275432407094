@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src:
    local('Lato Light'),
    local('Lato-Light'),
    url('../fonts/lato/Lato-Light.woff') format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src:
    local('Lato'),
    local('Lato-Regular'),
    url('../fonts/lato/Lato-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src:
    local('Lato Bold'),
    local('Lato-Bold'),
    url('../fonts/lato/Lato-Bold.woff') format('woff');
}

@font-face {
  font-family: 'ChunkFive-Roman';
  font-style: normal;
  font-weight: normal;
  src:
    local('ChunkFive-Roman'),
    url('../fonts/Chunkfive.woff') format('woff');
}
