@import './app/material-overrides.scss';
@import './app/material.theme.scss';
@import './css/font.css';

@import './css/description.scss';
@import './css/markers.scss';
@import './css/mapbox-custom.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --app-max-width: 80vw;
  --app-min-width: initial;
  --app-width: 1200px;
}
