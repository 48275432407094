// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$wemap-palette: (
  50: #e4f3ff,
  100: #bee1ff,
  200: #95cfff,
  300: #6bbbff,
  400: #4cacff,
  500: #349dff,
  600: #328ff5,
  700: #2f7ce1,
  800: #2c6bcf,
  900: #0047ba,
  A100: #fcfdff,
  A200: #c9d7ff,
  A400: #96b2ff,
  A700: #7ca0ff,

  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$my-typography: mat.m2-define-typography-level(
  $font-family: 'Lato',
  $font-weight: 400,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: normal
);

$primary: mat.m2-define-palette($wemap-palette, 700);
$primary-dark: mat.m2-define-palette($wemap-palette, 900);
$my-accent: mat.m2-define-palette(mat.$m2-orange-palette, A200, A100, A400);

$fontConfig: (
  display-4: mat.m2-define-typography-level(112px, 112px, 300, 'Lato', -0.0134em),
  display-3: mat.m2-define-typography-level(56px, 56px, 400, 'Lato', -0.0089em),
  display-2: mat.m2-define-typography-level(45px, 48px, 400, 'Lato', 0em),
  display-1: mat.m2-define-typography-level(34px, 40px, 400, 'Lato', 0.0074em),
  headline: mat.m2-define-typography-level(24px, 32px, 400, 'Lato', 0em),
  title: mat.m2-define-typography-level(20px, 32px, 500, 'Lato', 0.0075em),
  subheading-2: mat.m2-define-typography-level(16px, 28px, 400, 'Lato', 0.0094em),
  subheading-1: mat.m2-define-typography-level(15px, 24px, 500, 'Lato', 0.0067em),
  body-2: mat.m2-define-typography-level(14px, 24px, 500, 'Lato', 0.0179em),
  body-1: mat.m2-define-typography-level(14px, 20px, 400, 'Lato', 0.0179em),
  button: mat.m2-define-typography-level(14px, 14px, 500, 'Lato', 0.01em),
  caption: mat.m2-define-typography-level(12px, 20px, 400, 'Lato', 0.0333em),
  input: mat.m2-define-typography-level(inherit, 1.125, 400, 'Lato', 1.5px)
);

$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $my-accent
    ),
    typography: $fontConfig
  )
);

@include mat.core-theme($my-theme);
@include mat.all-component-themes($my-theme);

// Add some material override class
// Button
a[class*='mat-button'],
button[class*='mat-button'] {
  &.mat-mdc-icon-button {
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-wrapper {
      display: flex;
      justify-content: center;
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  &.mat-button-full {
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  &.mat-button-icon-label {
    mat-icon {
      margin-right: 4px;

      &[data-mat-icon-type='svg'] {
        margin-right: 6px;
      }
    }
  }
}

:root {
  --color-primary: #{mat.m2-get-color-from-palette($primary)};
  --color-primary-dark: #{mat.m2-get-color-from-palette($primary-dark)};
}
