/* PINPOINTS */

.wemap-pinpoint-draft-marker {
  transition: none !important;
  margin-top: 10px;

  > * {
    transform: scale(0.45);
    transform-origin: bottom;
  }
}

.wemap-pinpoint-marker,
.wemap-pinpoint-draft-marker {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #333333;
  opacity: 1;
  user-select: none;
  image-rendering: -webkit-optimize-contrast;
  position: absolute;
  transform-origin: 50% 93%;
  width: 82px;
  height: 100px;

  /* Transition when pinpoint appears */
  transform: scale(0.65);
  transition: all 0.2s cubic-bezier(0, 1.27, 0.6, 1.64);

  /* shadow */
  &::after {
    content: ' ';
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 37%;
    top: 74%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 0.08) 25%, transparent 50%);
    transform: rotateX(70deg);
  }

  &.showing {
    opacity: 0;
    transform: scale(0);
  }

  &.hovered,
  &.opened {
    transform: scale(1);
    transition: all 0.1s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  }

  .shape {
    padding: 3px;
    position: absolute;
    z-index: 2;
    background: #fafafa;
    width: 82px;
    height: 82px;
    /* like/bookmark */
    transition: background 0.2s ease-out;

    &::after {
      position: absolute;
      z-index: 10;
      content: ' ';
      height: 0;
      width: 0;
      top: 98%;
      left: 50%;
      margin-left: -12px;
      border: solid transparent;
      border-color: transparent;
      border-top-color: #fafafa;
      border-width: 12px;
      pointer-events: none;
      transition: border-top-color 0.2s ease-out;
    }

    .wemap-pinpoint-marker-image {
      width: 100%;
      height: 100%;
      background-size: contain;
    }

    .play-button {
      top: 50%;
      left: 50%;
      line-height: 30px;
      width: 30px;
      height: 30px;
      margin-top: -15px;
      margin-left: -15px;
      color: #fff;
      display: none;
      font-size: 17px;
      text-align: center;
      border-radius: 50%;
      position: absolute;
      border: 2px solid #fff;
      background: rgba(255, 255, 255, 0.4);

      .fa.fa-play {
        color: #fff;
        font-size: 17px;
        margin-left: 4px;
      }
    }
  }

  .popup-container {
    display: block;
    position: absolute;
    top: -65px;
    left: 50%;
    width: 300px;
    margin-left: -150px;

    .popup {
      margin: 0;
      color: #222222;
      padding: 7px;
      font-size: 14px;
      text-align: center;
      border-radius: 3px;
      font-weight: 400;
      word-break: break-word;
      background: transparent;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  &.round {
    .shape {
      border-radius: 50%;
    }

    .wemap-pinpoint-marker-image {
      border-radius: 50%;
    }
  }

  .square {
    background: none;

    .shape {
      border-radius: 5px;
    }

    .wemap-pinpoint-marker-image {
      border-radius: 2px;
    }
  }
}

geocode-map .wemap-pinpoint-marker {
  transition: 0s;
}

.wemap-pinpoint-marker .wemap-pinpoint-marker[data-type='3'] .shape .play-button {
  display: block;
}
