[hidden] {
  display: none !important;
}

// Button with truncate
button.mdc-button.truncate {
  overflow: hidden;
  display: block;
}

.mat-mdc-icon-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  mat-icon {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

.mat-mdc-cell {
  height: 4rem !important;
}

.mat-mdc-header-cell {
  font-size: 0.875rem !important;
  font-weight: 800 !important;
  color: #4b5563 !important;
}

.mdc-notched-outline__notch {
  border-right: none;
}

.analytics-select {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

// Reduce the height of the menu items when it's an option menu button
.options-menu {
  .mat-mdc-menu-content {
    padding: 0 !important;

    .mat-mdc-menu-item {
      height: 38px;
      min-height: 38px;
      display: flex;
      align-items: center;
    }

    .mat-mdc-menu-item .mat-icon {
      display: flex !important;
    }
  }
}

.mat-mdc-menu-panel[class*=' w-'] {
  min-width: unset !important;
  max-width: unset !important;
}

// Chips
mat-chip-option.chip-styled-override {
  // Base
  --mdc-chip-elevated-container-color: transparent !important;
  --mdc-chip-label-text-color: #0047ba !important;
  
  // Outline
  --mdc-chip-outline-width: 1px !important;
  --mdc-chip-outline-color: #e4f3ff !important;
  
  // Selected
  --mdc-chip-elevated-selected-container-color: #e4f3ff !important;
  --mdc-chip-selected-label-text-color: #0047ba !important;
  --mdc-chip-with-icon-selected-icon-color: #0047ba !important;

  // Focus
  --mdc-chip-focus-state-layer-opacity: 0.08 !important;
}
